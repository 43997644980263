var $ = require("jquery");
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const isLargeScreen = window.matchMedia("(min-width: 1025px)").matches;
const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;

document.querySelectorAll('[data-splitting="words"]').forEach(function(heading) {
  gsap.delayedCall(.5, animateWords);
  var duration = 1.4;
  var stagger = 0.05;

  function animateWords() {
    gsap.to(heading.querySelectorAll(".word"), {
      scrollTrigger: {
        trigger: heading,
        start: 'top 80%',
      },
      webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "power4.out",
      opacity: 1,
      duration: duration,
      stagger: stagger
    });

    gsap.to(heading.querySelectorAll(".word span"), {
      scrollTrigger: {
        trigger: heading,
        start: 'top 80%',
      },
      y: 0,
      ease: "power4.out",
      duration: duration,
      stagger: stagger
    });
  };
});

const aboutIntroImages = document.querySelectorAll('.about-intro__image');
if (aboutIntroImages.length) {
  gsap.utils.toArray(aboutIntroImages).forEach((image, index) => {
    const yStart = 50 + index * 150;
    const yEnd = 0;

    gsap.fromTo(
      image,
      { y: yStart },
      {
        y: yEnd,
        scrollTrigger: {
          trigger: ".about-intro .container",
          start: "top 70%",
          end: "bottom 60%",
          scrub: 1 + index,
        },
      }
    );
  });
}

const solutionsIntroImages = document.querySelectorAll(".solutions-intro img");
if (solutionsIntroImages.length) {
  solutionsIntroImages.forEach((element, index) => {

    if (index === 0) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 100%",
          end: "center center",
          once: true,
        },
        x: -100,
        opacity: 0,
        duration: 2.5,
        ease: "power4.out",
      });
    } else if (index === 1) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 100%",
          end: "center center",
          once: true,
          scrub: 2,
        },
        y: 150,
        opacity: .5,
        duration: 1.2,
        ease: "power2.out",
      });
    }
  });
}

const upArrow = document.querySelector('.up-arrow-animate');
if (upArrow) {
  gsap.from(upArrow, {
    y: '40%',
    scrollTrigger: {
      trigger: upArrow,
      start: "top bottom",
      end: "bottom bottom",
      scrub: 3,
    },
  });
}

const childPageCards = document.querySelectorAll(".child-page-grid__grid .child-page-card");
if (childPageCards.length) {
  childPageCards.forEach((element, index) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "bottom 80%",
        once: true,
        scrub: 1 + index,
      },
      y: 200,
      opacity: 0,
      duration: 1.2,
      ease: "power2.out",
    });
  });
}

const xtgProjectCards = document.querySelectorAll(".project-card");
if (xtgProjectCards.length) {
  xtgProjectCards.forEach((element, index) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "bottom 80%",
        once: true,
      },
      y: 100,
      opacity: 0,
      duration: 2,
      ease: "power2.out",
    });
  });
}

const eventDetailsList = document.querySelectorAll(".event-details-list__details__list li");
if (eventDetailsList.length) {
  eventDetailsList.forEach((element, index) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "bottom 80%",
        once: true,
      },
      y: 100,
      opacity: 0,
      duration: 1.2,
      ease: "power2.out",
    });
  });
}

const eventAgendaList = document.querySelectorAll(".event-agenda__list .timetable-row");
if (eventAgendaList.length) {
  eventAgendaList.forEach((element, index) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "bottom 80%",
        once: true,
      },
      y: 100,
      opacity: 0,
      duration: 1.2,
      ease: "power2.out",
    });
  });
}

const contentListItems = document.querySelectorAll(".content-list__list li");
if (contentListItems.length) {
  contentListItems.forEach((element, index) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "bottom 80%",
        once: true,
      },
      x: -30,
      opacity: 0,
      duration: 2,
      ease: "power2.out",
      delay: index * 0.15,
    });
  });
}

const statsGrid = document.querySelectorAll(".stats-grid__grid");
if (statsGrid.length) {
  statsGrid.forEach(container => {
    const int_items = container.querySelectorAll(".stats-grid__grid .stat .h1 span.int");
    const dec_items = container.querySelectorAll(".stats-grid__grid .stat .h1 span.dec");

    // Use ScrollTrigger to trigger animations when the container is in view
    gsap.from(int_items, {
      textContent: 0,
      duration: 4,
      ease: "power1.out",
      snap: { textContent: 1 },
      stagger: {
        each: 0.2,
        onUpdate: function () {
          this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
        },
      },
      scrollTrigger: {
        trigger: container,
        start: "top 100%",
        once: true,
      },
    });

    gsap.from(dec_items, {
      textContent: 0,
      duration: 4,
      ease: "power1.out",
      snap: { textContent: 0.1 },
      scrollTrigger: {
        trigger: container,
        start: "top 100%",
        once: true,
      },
    });
  });

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

const statsCards = document.querySelectorAll(".stats-cards__grid");
if (statsCards.length) {
  statsCards.forEach(container => {
    const intItems = container.querySelectorAll(".stat .h1 span.int");
    const decItems = container.querySelectorAll(".stat .h1 span.dec");

    // Use ScrollTrigger to trigger animations when the container is in view
    gsap.from(intItems, {
      textContent: 0,
      duration: 4,
      ease: "power1.out",
      snap: { textContent: 1 },
      stagger: {
        each: 0.2,
        onUpdate: function () {
          this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
        },
      },
      scrollTrigger: {
        trigger: container,
        start: "top 100%",
        once: true,
      },
    });

    gsap.from(decItems, {
      textContent: 0,
      duration: 4,
      ease: "power1.out",
      snap: { textContent: 0.1 },
      scrollTrigger: {
        trigger: container,
        start: "top 100%",
        once: true,
      },
    });
  });

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}


const introHeadingArrow = document.querySelector('.heading-text__arrow');
if (introHeadingArrow) {
  gsap.from(introHeadingArrow, {
    y: '-50px',
    x: '-50px',
    opacity: 0,
    ease: "power2.out",
    duration: 2,
    scrollTrigger: {
      trigger: introHeadingArrow,
      start: "top bottom",
    },
  });
}

const textCols = $(".text-columns");
if (textCols) {
  let arrows = $(".text-columns .text-col svg");
  gsap.from(arrows, {
      duration: 1,
      opacity: 0,
      x: -20,
      y: -20,
      stagger: 0.35,
      ease: "power2.out",
      scrollTrigger: {
          trigger: ".text-columns",
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none none",
      }
  });
}

const videoFiltersArrow = document.querySelector('.video-filters__header svg');
if (videoFiltersArrow) {
  gsap.from(videoFiltersArrow, {
    y: '-50px',
    x: '50px',
    opacity: 0,
    ease: "power2.out",
    duration: 2,
    scrollTrigger: {
      trigger: videoFiltersArrow,
      start: "top bottom",
    },
  });
}

// SMALL DEVICES ONLY
if(isSmallScreen) {
 const imageTextImages = document.querySelectorAll(".intro-image-text img");
  if (imageTextImages.length) {
    imageTextImages.forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 100%",
          end: "center center",
          once: true,
          scrub: 1,
        },
        y: 100,
        opacity: .5,
        ease: "power2.out",
      });
    });
  }
}

// LARGE DEVICES ONLY
if(isLargeScreen) {
  window.addEventListener('load', function() {
    setTimeout(function() {
      if (isLargeScreen && document.querySelector('.footer')) {
        gsap.to('.footer', {
          scrollTrigger: {
            trigger: '.footer-pusher',
            start: 'top bottom',
            end: '+=80%',
            scrub: true,
          },
          y: '0',
          duration: 1,
          ease: 'linear',
        });
      }
    }, 200);

    const resizeObserver = new ResizeObserver(() => {
      ScrollTrigger.refresh();
    });

    resizeObserver.observe(document.body);
  });

  const heroBg = document.querySelector('.hero__bg');
  if (heroBg) {
    gsap.to(heroBg, {
      y: '40%',
      scale: 1.1,
      scrollTrigger: {
        trigger: '.hero',
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const hero__content = document.querySelector('.hero__content');
  if (hero__content) {
    gsap.to(hero__content, {
      y: '80%',
      opacity: 0,
      scrollTrigger: {
        trigger: '.hero',
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const videoFull = document.querySelector('.video--template-2 video');
  if (videoFull) {
    gsap.from(videoFull, {
      y: '-40%',
      scrollTrigger: {
        trigger: '.video--template-2',
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }

  const missionStatement = document.querySelector('.xtg-mission-statement img');
  if (missionStatement) {
    gsap.from(missionStatement, {
      y: '-40%',
      scrollTrigger: {
        trigger: '.xtg-mission-statement',
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }

  const imageTextImages = document.querySelectorAll(".intro-image-text img");
  if (imageTextImages.length) {
    imageTextImages.forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 100%",
          end: "center center",
          once: true,
          scrub: 1.5,
        },
        y: 160,
        opacity: .5,
        duration: 1.2,
      });
    });
  }

  const xtg_image = document.querySelector('.xtg-cta__image img');
  if (xtg_image) {
    gsap.from(xtg_image, {
      y: '-30%',
      ease: 'none',
      scrollTrigger: {
        trigger: '.xtg-cta',
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  // const template_3_image = document.querySelector('.image-text--template-3 img');
  // if (template_3_image) {
  //   gsap.from(template_3_image, {
  //     y: '-20%',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '.image-text--template-3',
  //       start: "top bottom",
  //       end: "bottom top",
  //       scrub: true,
  //     },
  //   });
  // }

  const event_image_tex = document.querySelector('.event-image-text__image img');
  if (event_image_tex) {
    gsap.from(event_image_tex, {
      y: '-20%',
      ease: 'none',
      scrollTrigger: {
        trigger: '.event-image-text',
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const child_page_grid_arrow = document.querySelector('.child-page-grid__arrow');
  if (child_page_grid_arrow) {
    gsap.from(child_page_grid_arrow, {
      y: '150px',
      x: '-150px',
      ease: 'none',
      scrollTrigger: {
        trigger: '.child-page-grid',
        start: "top bottom",
        end: "top+=200", // 200px after the start of the trigger
        scrub: 1.5,
      },
    });
  }

  const $iconCols = $(".icon-col");
  if ($iconCols.length) {
    gsap.timeline({
        scrollTrigger: {
            trigger: ".icons-grid__grid",
            start: "top 80%",
        },
    })
    .from($iconCols.toArray(), {
        opacity: 0,
        y: 50,
        duration: 1.5,
        stagger: 0.2,
        ease: "power2.out",
    });
  }

  const xtgIntroImage = document.querySelectorAll(".xtg-intro__content img");
  if (xtgIntroImage.length) {
    gsap.from(xtgIntroImage, {
      scrollTrigger: {
        trigger: xtgIntroImage,
        start: "top 80%",
        end: "center center",
        once: true,
      },
      x: -100,
      opacity: 0,
      duration: 2.5,
      ease: "power4.out",
    });
  }

  const bannerImaged = document.querySelector('.banner-text__img picture');
  if (bannerImaged) {
    gsap.to(bannerImaged, {
      y: '40%',
      scale: 1.1,
      scrollTrigger: {
        trigger: '.banner-text__img',
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  }
}

/* ====================== TEAM MODULE AJAX FILTERING ======================  */
  // Handle desktop button click
  function handleDesktopFilter() {
      $('.team-profiles__filter-button').on('click', function () {
          const department = $(this).data('department');

          // Update active button state
          $('.team-profiles__filter-button')
              .removeClass('team-profiles__filter-button--active')
              .attr('aria-selected', 'false');
          $(this)
              .addClass('team-profiles__filter-button--active')
              .attr('aria-selected', 'true');

          fetchProfiles(department);
      });
  }

  // Handle mobile dropdown change
  function handleMobileFilter() {
      $('.team-profiles__filter--mobile').on('change', function () {
          const department = $(this).val();
          fetchProfiles(department);
      });
  }

  // AJAX Fetch Profiles Function
  function fetchProfiles(department) {
      const $profilesContainer = $('.team-profiles__profiles .container');
      const $profilesItems = $profilesContainer.children();

      // Animate current items out
      gsap.to($profilesItems, {
          opacity: 0,
          scale: 0.9,
          duration: 0.4,
          stagger: 0,
          onComplete: function () {
              // Fetch profiles via AJAX
              $.ajax({
                  url: avi_ajax.ajax_url,
                  method: 'POST',
                  data: {
                      action: 'filter_team_members',
                      department: department,
                  },
                  success: function (response) {
                      // Update content
                      $profilesContainer.html(response);

                      // Animate new items in
                      const $newProfilesItems = $profilesContainer.children();
                      gsap.fromTo(
                          $newProfilesItems,
                          { opacity: 0, scale: 0.9, y: 20 },
                          { opacity: 1, scale: 1, y: 0, duration: 0.6, stagger: 0.1, ease: 'power2.out' }
                      );
                  },
                  error: function () {
                      $profilesContainer.html('<p class="team-profiles__error">Error loading team members.</p>');
                      gsap.fromTo(
                          $profilesContainer,
                          { opacity: 0, y: 20 },
                          { opacity: 1, y: 0, duration: 0.5 }
                      );
                  },
              });
          },
      });
  }

  // Initialise filters based on viewport
  function initFilters() {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;

      if (isMobile) {
          $('.team-profiles__filter--mobile').show();
          $('.team-profiles__filter--desktop').hide();
          handleMobileFilter();
      } else {
          $('.team-profiles__filter--mobile').hide();
          $('.team-profiles__filter--desktop').show();
          handleDesktopFilter();
      }
  }

  // Run on page load and on window resize
  initFilters();
  $(window).on('resize', initFilters);




/* ====================== Video MODULE AJAX FILTERING ======================  */
    function handleVideoDesktopFilter() {
        $('.video-filters__filter-button').on('click', function () {
            const filterIndex = $(this).data('filter-index');

            // Update active button state
            $('.video-filters__filter-button')
                .removeClass('video-filters__filter-button--active')
                .attr('aria-selected', 'false');
            $(this)
                .addClass('video-filters__filter-button--active')
                .attr('aria-selected', 'true');

            // Update video content
            fetchVideoContent(filterIndex);
        });
    }

    // Handle mobile dropdown change for video filters
    function handleVideoMobileFilter() {
        $('.video-filters__filter--mobile').on('change', function () {
            const filterIndex = $(this).val();
            fetchVideoContent(filterIndex);
        });
    }

    // Fetch and display videos with GSAP animation
    function fetchVideoContent(filterIndex) {
        const $videosContainer = $('.video-filters__content');
        const $activeVideos = $videosContainer.find('.video-filters__videos--active');

        // Animate current videos out
        gsap.to($activeVideos, {
            opacity: 0,
            scale: 0.9,
            duration: 0.4,
            onComplete: function () {
                // Hide current videos and show the relevant section
                $activeVideos.removeClass('video-filters__videos--active');
                const $newVideos = $(`#filter-${filterIndex}`);
                $newVideos.addClass('video-filters__videos--active');

                // Animate new videos in
                gsap.fromTo(
                    $newVideos,
                    { opacity: 0, scale: 0.9, y: 20 },
                    { opacity: 1, scale: 1, y: 0, duration: 0.6, stagger: 0.1, ease: 'power2.out' }
                );
            },
        });
    }

    // Initialise video filters based on viewport
    function initVideoFilters() {
        const isMobile = window.matchMedia('(max-width: 768px)').matches;

        if (isMobile) {
            $('.video-filters__filter--mobile').show();
            $('.video-filters__filter--desktop').hide();
            handleVideoMobileFilter();
        } else {
            $('.video-filters__filter--mobile').hide();
            $('.video-filters__filter--desktop').show();
            handleVideoDesktopFilter();
        }
    }

    // Run on page load and on window resize for video filters
    initVideoFilters();
    $(window).on('resize', initVideoFilters);
