import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

const isLargeScreen = window.matchMedia("(min-width: 1025px)").matches;
const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;
const isXSmallScreen = window.matchMedia("(max-width: 649px)").matches;
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

jQuery(document).ready(function($) {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && window.innerHeight > window.innerWidth) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh+'px');
        document.documentElement.style.setProperty('--staticvh', vh+"px");
        window.addEventListener('resize', function() {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', vh+'px');
        });
    }

/* ====================== FANCYBOX ====================== */
   Fancybox.bind("[data-fancybox]", {
        Toolbar: {
            display: {
                left: [],
                middle: ["infobar"],
                right: ["close"],
            },
        },
        Thumbs: false,
    });


/* ====================== HERO H1 ======================  */
  Splitting();
  const wordElements = document.querySelectorAll('[data-splitting="words"] .word');

  wordElements.forEach(wordElement => {
    const span = document.createElement('span');
    while (wordElement.firstChild) {
      span.appendChild(wordElement.firstChild);
    }
    wordElement.appendChild(span);
  });

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

 /* ====================== FOOTER PUSHER ======================  */
    var referenceHeight = $('.footer').outerHeight();
    $('.footer-pusher').height(referenceHeight);

 /* ====================== SCROLL TOP ======================  */
    $('.scroll-top').on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, 1000);
    });
/* ====================== SCROLL FUNCTIONS ======================  */
    var lastScrollTop = 0;
    var headerHeight = $('.header').outerHeight();
    if (isLargeScreen) {
        $(window).scroll(function(){
            var st = $(this).scrollTop();

            if(st > 0) {
                $(".header").addClass("header--fixed");
            } else {
                $(".header").removeClass("header--fixed");
            }

            lastScrollTop = st;
        });
    }

    $('.scroll-to-main').click(function(e) {
        e.preventDefault();
        if (isLargeScreen) {
            $('html, body').animate({
                scrollTop: $('.hero').offset().top + $('.hero').outerHeight() - 86
            }, 600);
        } else {
            $('html, body').animate({
                scrollTop: $('.hero').offset().top + $('.hero').outerHeight()
            }, 600);
        }

    });

/* ====================== CLICK FUNCTIONS ======================  */
    if ($('section.video').length) {
        if (isXSmallScreen) {
           $('.video__wrap').on('click', function () {
                const $video = $(this).find('video');

                if ($video.length) {
                    // Play the video
                    $video[0].play();

                    // Trigger fullscreen mode if supported
                    if ($video[0].requestFullscreen) {
                        $video[0].requestFullscreen();
                    } else if ($video[0].webkitEnterFullscreen) {
                        // For iOS Safari
                        $video[0].webkitEnterFullscreen();
                    }
                }
            });

            // Listen for fullscreen changes
            $(document).on('fullscreenchange webkitfullscreenchange', function () {
                const $video = $('video').filter(function () {
                    return this.readyState > 0 && !this.paused; // Find any playing video
                });

                if ($video.length && !document.fullscreenElement && !document.webkitFullscreenElement) {
                    // Pause the video
                    $video[0].pause();

                    // Reset the video to show the placeholder
                    $video[0].currentTime = 0;

                    // Reload the video source to force the placeholder to display
                    $video[0].load();
                }
            });
        } else {
            const playButton = $('.video-play-btn');
            const video = $('video.accessible-video');

            // Play button click event
            playButton.on('click', function () {
                video[0].play(); // Play the video
                video.attr('controls', true); // Show controls
                $(this).fadeOut(300); // Fade out the play button
            });

            // Ensure the video can be played using space/enter when the play button is focused
            playButton.on('keydown', function (e) {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    playButton.click();
                }
            });
        }
    }

    // MENU
    $('.header__navigation__nav>.menu-item-has-children > a').on('click', function (e) {
        e.preventDefault(); // Prevent the default link behavior

        const parentMenuItem = $(this).parent();
        const megaMenu = parentMenuItem.find('.mega-menu');
        const isCurrentlyExpanded = parentMenuItem.hasClass('active');

        // Close all other open menus
        $('.menu-item-has-children.active').each(function() {
            const otherMenuItem = $(this);
            if (otherMenuItem[0] !== parentMenuItem[0]) {
                const otherMegaMenu = otherMenuItem.find('.mega-menu');
                otherMenuItem.removeClass('active');
                otherMenuItem.find('a').attr('aria-expanded', 'false');
                otherMegaMenu.attr('aria-hidden', 'true');
            }
        });

        // Toggle the current menu
        if (isCurrentlyExpanded) {
            // Close the current menu if it was already open
            parentMenuItem.removeClass('active');
            $(this).attr('aria-expanded', 'false');
            megaMenu.attr('aria-hidden', 'true');
        } else {
            // Open the current menu
            parentMenuItem.addClass('active');
            $(this).attr('aria-expanded', 'true');
            megaMenu.attr('aria-hidden', 'false');
        }

        // Add/remove no-scroll class on the body
        if ($('.menu-item-has-children.active').length) {
            $('body').addClass('no-scroll');
        } else {
            $('body').removeClass('no-scroll');
        }
    });

      // When the '.submenu-close' is clicked
      $('.submenu-close, .mega-menu__back').on('click', function () {
        const megaMenu = $(this).closest('.mega-menu');
        const parentMenuItem = megaMenu.closest('.menu-item-has-children');

        // Remove active class
        parentMenuItem.removeClass('active');

        // Update ARIA attributes
        parentMenuItem.find('> a').attr('aria-expanded', false);
        megaMenu.attr('aria-hidden', true);

        // Remove no-scroll class if no menus are open
        if (!$('.menu-item-has-children.active').length) {
          $('body').removeClass('no-scroll');
        }
      });

      // Mobile
      if (isSmallScreen) {
         $('.header__nav-toggle').on('click', function (e) {
            e.preventDefault();
            $('.header__navigation').addClass('visible');
            $('body').addClass('no-scroll');
        });

        $('.header__navigation__header__close').on('click', function (e) {
            e.preventDefault();
            $('body').removeClass('no-scroll');
            $('.header__navigation').removeClass('visible');
            $('.header__navigation .mega-menu[aria-hidden="false"]').attr('aria-hidden', 'true');

            setTimeout(() => {
                $('.header__navigation [aria-expanded="true"]').attr('aria-expanded', 'false');
                $('.header__navigation .sub-menu[aria-hidden="false"]').slideUp().attr('aria-hidden', 'true');
            },200);
        });

        $('.mega-menu .container>.sub-menu>li.has-children>a').on('click', function (e) {
            if($(this).attr('aria-expanded') === 'false') {
                e.preventDefault();
                $(this).attr('aria-expanded', 'true');
                $(this).next().slideDown().attr('aria-hidden', 'false');
            }
        });

        $('.mega-menu__back').on('click', function () {
            $(this).parent().find('a[aria-expanded="true"]').attr('aria-expanded', 'false');
            setTimeout(() => {
                $(this).parent().find('.sub-menu[aria-hidden="false"]').slideUp().attr('aria-hidden', 'true');
            },200);
        });
      }

/* ====================== IMAGE TAB MODULE ======================  */
   $('.image-tab-filter').on('click', '.tab-heading', function () {
        const $this = $(this);
        const targetId = $this.attr('aria-controls');

        // Update tab heading states
        $this
            .attr('aria-selected', 'true')
            .siblings()
            .attr('aria-selected', 'false');

        // Update tab panel states
        $('#' + targetId)
            .attr('aria-hidden', 'false')
            .slideDown()
            .siblings('.tab-panel')
            .attr('aria-hidden', 'true')
            .slideUp();
    });

/* ====================== CONTACT SIDEBAR ======================  */
   // By default, make all sidebar elements non-tabbable
    $('#sidebar').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '-1');

    // Open the sidebar
    $('.header__contact, a[href="#contact"]').on('click', function (e) {
        e.preventDefault();
        $('#sidebar').addClass('open').attr('aria-hidden', 'false').removeAttr('tabindex'); // Remove tabindex from sidebar itself

        // Once the sidebar is opened, make all elements inside it tabbable
        $('#sidebar').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '0');
    });

    // Close the sidebar
    $('.header__sidebar__close').on('click', function (e) {
        e.preventDefault();
        closeSidebar();
    });

    // Close sidebar on Escape key
    $(document).on('keydown', function (e) {
         if ((e.key === 'Escape' || e.keyCode === 27) && $('#sidebar').hasClass('open')) {
            closeSidebar();
        }
    });

    // When HubSpot form is ready, make sure all elements in the sidebar are non-tabbable
    document.addEventListener('hubspotFormReady', function() {
        $('#sidebar').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '-1');
    });

    // Close sidebar and reset tabindex and aria-hidden attributes
    function closeSidebar() {
        $('#sidebar').removeClass('open').attr('aria-hidden', 'true').attr('tabindex', '-1');
        // Make all elements inside the sidebar non-tabbable when closed
        $('#sidebar').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '-1');
    }

    // Detect focus going past the submit button (i.e., moving focus out of the sidebar)
   $('#sidebar').on('focusout', 'form input, form textarea, form button', function(e) {
        const submitButton = $(this).closest('form').find('input[type="submit"], button[type="submit"]');
        const focusedOutElement = e.target;

        // Check if focus moved past the submit button
        if (focusedOutElement === submitButton[0]) {
            closeSidebar(); // Focus is still on the submit button, no need to close the sidebar
        }
    });

/* ====================== HEADER SEARCH BAR ======================  */
    // By default, make all searchbar elements non-tabbable
    $('.header__searchform').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '-1');

    // Open the sidebar
    $('.search-toggle').on('click', function (e) {
        e.preventDefault();
        $('.header__searchform').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '0');
        $('.header__searchform').addClass('open').attr('aria-hidden', 'false');
        $('.header__searchform input[type="search"]').focus();
    });

    $('.header__searchform__close').on('click', function (e) {
        e.preventDefault();
        closeSearchbar();
    });

    function closeSearchbar() {
        $('.header__searchform').find('button, a, input, select, textarea, [tabindex]').attr('tabindex', '-1');
        $('.header__searchform').removeClass('open').attr('aria-hidden', 'true');
        $('.header__search-toggle').focus();
    }

    // Close search on Escape key
    $(document).on('keydown', function (e) {
        if ((e.key === 'Escape' || e.keyCode === 27) && $('.header__searchform').hasClass('open')) {
            closeSearchbar();
        }
    });


/* ====================== VIDEO BLOCK ======================  */
    $('figure.wp-block-video').on('click', function() {
        $(this).find('video').attr('controls', 'controls');
        $(this).addClass('played');
    });

/* ====================== EVENTS FILTERS ======================  */
    $('.event-tags__link').on('click', function(e) {
        e.preventDefault();

        const tagId = $(this).data('event-tag-id');
        const tagName = $(this).data('event-tag-slug');

        $('.event-tags__link').removeClass('active');
        $(this).addClass('active');

        if (tagId === 'all') {
            $('.news-card').show();
        } else {
            $('.news-card').each(function() {
                const postTags = $(this).data('category').split(', '); // Split the data-category by comma
                if (postTags.includes(tagName)) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }
    });

/* ====================== BLOG FILTERS ======================  */
    $('.subcategories__link').on('click', function(e) {
        e.preventDefault();

        const tagId = $(this).data('category-id');
        const tagName = $(this).data('category-slug');
        console.log(tagName);

        $('.subcategories__link').removeClass('active');
        $(this).addClass('active');

        if (tagId === 'all') {
            $('.news-card').show();
        } else {
            $('.news-card').each(function() {
                const postTags = $(this).data('category').split(', '); // Split the data-category by comma
                if (postTags.includes(tagName)) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }
    });

/* ====================== XTG GALLERY ======================  */
    if ($('.xtg-gallery').length) {
        var isPaused = false;
        var $button = $('#marquee-toggle');
        var $marqueeGroups = $('.xtg-gallery__marquee__group');
        var $srText = $button.find('.visually-hidden');

        $button.on('click', function() {
            isPaused = !isPaused;
            $marqueeGroups.toggleClass('paused', isPaused);
            $button
                .attr('aria-pressed', isPaused)
                .attr('aria-label', isPaused ? 'Play Marquee' : 'Pause Marquee');
            $srText.text(isPaused ? 'Play' : 'Pause');
        });
    }

/* ====================== LOCATION ARCHIVE ======================  */
    if ($('body').hasClass('post-type-archive-location')) {
        $('.parent-terms .parent-term').on('click', function () {
            var termId = $(this).data('term');
            var termText = $(this).text();

            // Update active term text for Mobile
            $('.locations-wrap__filter-bar p').text(termText);

            // Toggle active class for clicked parent term
            $('.parent-terms .parent-term').removeClass('active');
            $(this).addClass('active');

            // Show child terms matching the parent term
            $('.accordion .accordion-item').hide();
            $('.accordion .accordion-item[data-parent-term="' + termId + '"]').show();

            // Adjust the map markers
            var visibleMarkers = [];
            $('.accordion .accordion-item[data-parent-term="' + termId + '"] .location-title').each(function () {
                var postId = $(this).data('id');
                visibleMarkers.push(postId);
            });

            // Filter and update the map
            if (window.currentMap && window.markerClusterer) {
                var bounds = new google.maps.LatLngBounds();
                var markersToCluster = [];

                window.currentMap.markers.forEach(function (marker) {
                    if (visibleMarkers.includes(marker.postId)) {
                        marker.setVisible(true);
                        bounds.extend(marker.getPosition());
                        markersToCluster.push(marker); // Include the marker for clustering
                    } else {
                        marker.setVisible(false);
                    }
                });

                // Only clear and re-add markers if the clusterer is initialized
                if (window.markerClusterer) {
                    window.markerClusterer.clearMarkers(); // Clear existing clusters
                    window.markerClusterer.addMarkers(markersToCluster); // Add visible markers back to the cluster
                }

                // Adjust map to show all visible markers
                if (bounds.isEmpty()) {
                    window.currentMap.setCenter({ lat: 0, lng: 0 });
                    window.currentMap.setZoom(10); // Default zoom
                } else {
                    window.currentMap.fitBounds(bounds);
                }
            }
        });

        if (isSmallScreen) {
            // Mobile Toggle for View (List/Map)
            const $toggleListButton = $('#toggle-list-view');
            const $toggleMapButton = $('#toggle-map-view');
            const $listView = $('.locations-wrap__list .accordion');
            const $mapView = $('.locations-wrap__map');

            // Set the default view to list
            $listView.show();
            $mapView.hide();

            // General function to switch views
            function toggleView(viewToShow, viewToHide, buttonToPress, buttonToUnpress) {
                viewToShow.show();
                viewToHide.hide();
                buttonToPress.attr('aria-pressed', 'true');
                buttonToUnpress.attr('aria-pressed', 'false');
            }

            // Add event listeners for List and Map Toggle
            $toggleListButton.on('click', function() {
                toggleView($listView, $mapView, $toggleListButton, $toggleMapButton);
            });

            $toggleMapButton.on('click', function() {
                toggleView($mapView, $listView, $toggleMapButton, $toggleListButton);
            });

            // Mobile Filter Popup
            const $filterToggleButton = $('.locations-wrap__filter-toggle');
            const $filterWrapper = $('.locations-wrap__filters');
            const $filterCloseButton = $('.locations-wrap__filters__close');
            const $applyFilterButton = $('.locations-wrap__filters__apply');

            // Function to open the filter popup
            function openFilterPopup() {
                $filterToggleButton.attr('aria-expanded', 'true');
                $filterWrapper.attr('aria-hidden', 'false');
                $filterCloseButton.attr('aria-hidden', 'false');
                $applyFilterButton.attr('aria-hidden', 'false');
            }

            // Function to close the filter popup
            function closeFilterPopup() {
                $filterToggleButton.attr('aria-expanded', 'false');
                $filterWrapper.attr('aria-hidden', 'true');
                $filterCloseButton.attr('aria-hidden', 'true');
                $applyFilterButton.attr('aria-hidden', 'true');
            }

            // Open filter popup when toggle button is clicked
            $filterToggleButton.on('click', openFilterPopup);

            // Close filter popup when close or apply button is clicked
            $filterCloseButton.on('click', closeFilterPopup);
            $applyFilterButton.on('click', closeFilterPopup);
        }


    }
    // Ensure the map instance is globally accessible
    $(document).ready(function () {
        $('.acf-map').each(function () {
            window.currentMap = initMap($(this));
        });
    });

/* ====================== CATEGORY BLOG SLIDER TABS ======================  */
    if ($('.blog-category').length) {
        // Tab functionality
       $('.blog-category .tab-button').on('click', function () {
            var targetPanel = $(this).attr('aria-controls');

            // Deactivate all tabs and panels
            $('.tab-button').removeClass('active').attr('aria-selected', 'false');
            $('.tab-panel').removeClass('active').attr('aria-hidden', 'true').slideUp();

            // Activate the selected tab and panel
            $(this).addClass('active').attr('aria-selected', 'true');
            $('#' + targetPanel).addClass('active').attr('aria-hidden', 'false').slideDown();
        });
    }

/* ====================== ACCORDIONS ======================  */
    if ($('.accordion').length) {
       // Accordion toggle functionality
        $(".accordion-header button").on("click", function () {
            const $button = $(this);
            const $accordionBody = $button.closest(".accordion-item").find(".accordion-collapse");

            // Slide up all accordion bodies except the clicked one
            $(".accordion-collapse").not($accordionBody).slideUp().attr("aria-hidden", "true");
            $(".accordion-header button").not($button).attr("aria-expanded", "false");

            // Toggle the clicked accordion body
            if ($accordionBody.is(":visible")) {
                $accordionBody.slideUp().attr("aria-hidden", "true");
                $button.attr("aria-expanded", "false");
            } else {
                $accordionBody.slideDown().attr("aria-hidden", "false");
                $button.attr("aria-expanded", "true");
            }
        });

        // Hide all accordion containers by default except the first
        $(".accordion:not(:first)").hide();
    }

/* ====================== SUPPLIER SUSTAINABILITY MATRIX ======================  */
    if ($('.supplier-sustainability-matrix').length) {
        // Dropdown filter functionality
        $("#supplier-filter").on("change", function () {
            const selectedPostId = $(this).val();

            // Hide all accordions
            $(".accordion").slideUp().attr("aria-hidden", "true");

            // Show the selected accordion
            if (selectedPostId) {
                $(`#accordion-${selectedPostId}`).slideDown().attr("aria-hidden", "false");
            }
        });
    }
});
