import { MarkerClusterer } from '@googlemaps/markerclusterer';
(function ($) {
    /**
     * initMap
     *
     * Renders a Google Map onto the selected jQuery element
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @return  object The map instance.
     */
    function initMap($el) {
        // Find marker elements within map.
        var $markers = $el.find('.marker');

        // Create generic map.
        var mapArgs = {
            zoom: $el.data('zoom') || 16,
            minZoom: 2,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: false,
        };
        var map = new google.maps.Map($el[0], mapArgs);

        // Add markers.
        map.markers = [];
        map.infoWindows = []; // To track InfoWindows for markers
        $markers.each(function () {
            initMarker($(this), map);
        });

        // Initialize the MarkerClusterer if available.
       if (typeof MarkerClusterer === "function") {
            window.markerClusterer = new MarkerClusterer({
                map: map,
                markers: map.markers, // Pass the markers array
                renderer: {
                    render: ({ count, position }) => {
                        // Determine color based on the cluster size (count)
                        return new google.maps.Marker({
                            position,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: '#065ba5', // Set color based on cluster size
                                fillOpacity: 1,
                                scale: 20, // Scale based on size
                                strokeColor: "#ffffff", // White border
                                strokeWeight: 1, // Border thickness
                            },
                            label: {
                                text: String(count), // Show cluster count
                                color: "#ffffff", // Label color
                                fontSize: "12px",
                                fontWeight: "bold",
                            },
                            title: `Cluster with ${count} markers` // Retain the title attribute with the cluster count
                        });
                    },
                },
            });
        }


        // Center map based on markers.
        centerMap(map);

        // Return map instance.
        return map;
    }

    // Expose initMap globally
    window.initMap = initMap;

    /**
     * initMarker
     *
     * Creates a marker for the given jQuery element and map.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $marker The jQuery element.
     * @param   object map The map instance.
     * @return  object The marker instance.
     */
    function initMarker($marker, map) {
        // Get position from marker.
        var lat = $marker.data("lat");
        var lng = $marker.data("lng");
        var postId = $marker.data("id"); // Get post ID
        var latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
        };

        var icon, size;
        if ($("body").hasClass("post-type-archive") || $("body").hasClass("single-location")) {
            icon = myTheme.templateUrl + "/library/images/graphics/map-pin.svg";
            size = { width: 33, height: 43 };
        } else {
            icon = myTheme.templateUrl + "/library/images/graphics/map-marker.svg";
            size = { width: 50, height: 50 };
        }

        var customIcon = {
            url: icon, // Path to your custom marker image
            scaledSize: new google.maps.Size(size.width, size.height), // Adjust size if needed
        };

        // Create marker instance.
        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: customIcon, // Add custom marker icon here
            postId: postId, // Attach the post ID for reference
        });

        // Append to reference for later use.
        map.markers.push(marker);

        // If marker contains HTML, add it to an infoWindow.
        if ($marker.html()) {
            // Create info window.
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html(),
            });

            // Track InfoWindow.
            map.infoWindows.push(infowindow);

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, "click", function () {
                // Close all other info windows
                map.infoWindows.forEach(function (iw) {
                    iw.close();
                });

                infowindow.open(map, marker);

                map.setCenter(marker.getPosition());
                map.setZoom(15);
            });
        }

        // Add event listener for titles with matching data-id
        $(`.location-title[data-id="${postId}"]`).on("click", function (e) {
            e.preventDefault();

            // Center the map on the marker and zoom in
            map.setCenter(marker.getPosition());
            map.setZoom(15);

            // Close all info windows
            map.infoWindows.forEach(function (iw) {
                iw.close();
            });

            // Open the current marker's info window
            if (infowindow) {
                infowindow.open(map, marker);
            }
        });

        $(".parent-term").on("click", function (e) {
            e.preventDefault();

            map.infoWindows.forEach(function (iw) {
                iw.close();
            });
        });
    }

    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    function centerMap(map) {
        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function (marker) {
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng(),
            });
        });

        // Case: Single marker.
        if (map.markers.length === 1) {
            map.setCenter(bounds.getCenter());
        } else {
            map.fitBounds(bounds);
        }
    }
})(jQuery);
