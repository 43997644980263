var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== SERVICES SLIDER ======================  */
// Get the pagination texts from the data attribute
if($('.customer-journey-carousel__slider').length) {
    const paginationTexts = JSON.parse(document.querySelector('.swiper-pagination').dataset.paginationTexts);
    var customerJourneySlider = new Swiper('.customer-journey-carousel__slider', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        threshold: 1,
        speed: 1000,
         autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.customer-journey-carousel .swiper-button-next',
            prevEl: '.customer-journey-carousel .swiper-button-prev',
        },
        pagination: {
            el: '.customer-journey-carousel .swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                const text = paginationTexts[index] || `Step ${index + 1}`;
                return `<span class="${className}">${text}</span>`;
            },
        },
        breakpoints: {
            650: {
                spaceBetween: 16,
                slidesPerView: 1.5,
            },
            1025: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 2.37,
                spaceBetween: 24,
            },
        },
    });

     // Add a 1-second delay before autoplay starts
    setTimeout(() => {
        customerJourneySlider.autoplay.start();
    }, 1000);
}

if($('.base-slider').length) {
    $('.base-slider').each(function () {
        var $sliderContainer = $(this);
        var $sliderElement = $sliderContainer.find('.base-slider__slider');
        var $slides = $sliderElement.find('.swiper-slide');

        new Swiper($sliderElement[0], {
            slidesPerView: 1,
            spaceBetween: 16,
            loop: $slides.length > 3,
            threshold: 1,
            speed: 1000,
            navigation: {
                nextEl: $sliderContainer.find('.swiper-button-next')[0],
                prevEl: $sliderContainer.find('.swiper-button-prev')[0],
            },
            pagination: {
                el: $sliderContainer.find('.swiper-pagination')[0],
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="' + currentClass + '"></span>' +
                        '<span class="divider"></span>' +
                        '<span class="' + totalClass + '"></span>'
                    );
                }
            },
            breakpoints: {
            650: {
                slidesPerView: 1.5,
            },

            1025: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 2.37,
                spaceBetween: 24,
            },
        },
        });
    });
}

if($('.case-study-links__grid').length) {
    var $sliderContainer = $('.case-study-links');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },
        breakpoints: {
            650: {
                spaceBetween: 16,
                slidesPerView: 1.75,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1025: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
        },
    });
}

if($('.event-speakers__slider').length) {
    var $sliderContainer = $('.event-speakers');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },
        breakpoints: {
            600: {
                slidesPerView: 1.5,
            },

            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 1.5,
                spaceBetween: 24,
            },
             1200: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
        },
    });
}


if($('.xtg-project-gallery__slider').length) {
    var $sliderContainer = $('.xtg-project-gallery');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },

         breakpoints: {
            600: {
                slidesPerView: 1.5,
            },

            768: {
                slidesPerView: 1.72,
            },
            1025: {
                spaceBetween: 24,
            },
        },
    });
}


if($('.testimonial-carousel__slider ').length) {
    var $sliderContainer = $('.testimonial-carousel');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 60,
        loop: true,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },
    });
}

if($('.blogs-slider__slider').length) {
    var $sliderContainer = $('.blogs-slider');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
       slidesPerView: 1,
        spaceBetween: 16,
        loop: false,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },
        breakpoints: {
            650: {
                slidesPerView: 1.5,
            },

            1025: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
        },
    });
}


if ($('.icons-grid__grid').length) {
    var $sliderContainer = $('.icons-grid');
    var $sliderElement = $sliderContainer.find('.swiper');
    var sliderInstance = null;

    function initOrDestroySlider() {
        var windowWidth = $(window).width();

        if (windowWidth <= 1024) {
            if (!sliderInstance) {
                sliderInstance = new Swiper($sliderElement[0], {
                    slidesPerView: 'auto',
                    spaceBetween: 60,
                    loop: false,
                    threshold: 1,
                    speed: 1000,
                    navigation: {
                        nextEl: $sliderContainer.find('.swiper-button-next')[0],
                        prevEl: $sliderContainer.find('.swiper-button-prev')[0],
                    },
                    pagination: {
                        el: $sliderContainer.find('.swiper-pagination')[0],
                        type: 'fraction',
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' + currentClass + '"></span>' +
                                '<span class="divider"></span>' +
                                '<span class="' + totalClass + '"></span>'
                            );
                        },
                    },
                    breakpoints: {
                        650: {
                            slidesPerView: 1.5,
                        },
                    },
                });
            }
        } else if (sliderInstance) {
            sliderInstance.destroy(true, true);
            sliderInstance = null;
        }
    }

    initOrDestroySlider();

    $(window).on('resize', function () {
        initOrDestroySlider();
    });
}

if($('.blog-category').length) {
    $('.blog-category .tab-panel').each(function () {
        var $sliderContainer = $(this);
        var $sliderElement = $sliderContainer.find('.swiper');
        var $slides = $sliderElement.find('.swiper-slide');

        new Swiper($sliderElement[0], {
            slidesPerView: 1,
            spaceBetween: 16,
            loop: false,
            threshold: 1,
            speed: 1000,
            navigation: {
                nextEl: $sliderContainer.find('.swiper-button-next')[0],
                prevEl: $sliderContainer.find('.swiper-button-prev')[0],
            },
            pagination: {
                el: $sliderContainer.find('.swiper-pagination')[0],
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="' + currentClass + '"></span>' +
                        '<span class="divider"></span>' +
                        '<span class="' + totalClass + '"></span>'
                    );
                }
            },
            breakpoints: {
                650: {
                    spaceBetween: 16,
                    slidesPerView: 1.75,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                },
            },
        });
    });
}

if ($('.numbered-slider').length) {
    var $sliderContainer = $('.numbered-slider');
    var $sliderElement = $sliderContainer.find('.swiper');
    var swiperInstance = null;

    function initSlider() {
        if (!swiperInstance) {
            swiperInstance = new Swiper($sliderElement[0], {
                slidesPerView: 'auto',
                spaceBetween: 80,
                threshold: 1,
                speed: 1000,
                navigation: {
                    nextEl: $sliderContainer.find('.swiper-button-next')[0],
                    prevEl: $sliderContainer.find('.swiper-button-prev')[0],
                },
                pagination: {
                    el: $sliderContainer.find('.swiper-pagination')[0],
                    type: 'fraction',
                    renderFraction: function (currentClass, totalClass) {
                        return (
                            '<span class="' + currentClass + '"></span>' +
                            '<span class="divider"></span>' +
                            '<span class="' + totalClass + '"></span>'
                        );
                    }
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 135,
                    },
                },
            });
        }
    }

    function destroySlider() {
        if (swiperInstance) {
            swiperInstance.destroy(true, true);
            swiperInstance = null;
        }
    }

    function handleResize() {
        if (window.matchMedia('(max-width: 649px)').matches) {
            destroySlider();
        } else {
            initSlider();
        }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
}

if($('.gallery-slider').length) {
    var $sliderContainer = $('.gallery-slider');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },

         breakpoints: {
            1025: {
                spaceBetween: 24,
                slidesPerView: 1.875,
            },
        },
    });
}


if($('.video-slider').length) {
    var $sliderContainer = $('.video-slider');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },

         breakpoints: {
            1025: {
                spaceBetween: 24,
                slidesPerView: 1.875,
            },
        },
    });
}
